import {
  BFormGroup,
  BInput,
  BTextarea,
  InputType,
} from '@/components/bootstrap'
import { FieldValidator } from '@/helpers/validation'
import { i18nStore } from '@/store'
import { emptyLocalizedField, LocalizedField } from '@/store/i18nStore'
import { Component, Prop, Ref, VModel } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import LanguageSelector from './LanguageSelector'
import './style.scoped.scss'

interface IFormInputProps {
  value?: string | number | null | LocalizedField
  onInput?: (value: any) => void
  fieldValidator?: FieldValidator<any>
  error?: string | boolean | null
  placeholder?: string
  description?: string
  label?: string
  type?: InputType
  disabled?: boolean
  maxlength?: number
  textarea?: boolean
  rows?: number
  prepend?: string

  onFocus?: () => void
  onClick?: () => void
}

@Component
export default class FormInput extends tsx.Component<IFormInputProps> {
  declare $scopedSlots: tsx.InnerScopedSlots<{
    append?: void
    top?: void
  }>

  @Ref() readonly inputEl!: HTMLInputElement | HTMLTextAreaElement

  @VModel()
  _value!: string | number | null | LocalizedField

  @Prop({ type: [String, Boolean] })
  readonly error?: string | boolean
  @Prop({ type: Object })
  readonly fieldValidator?: FieldValidator<any>
  @Prop({ type: String })
  readonly placeholder?: string
  @Prop({ type: String })
  readonly description?: string
  @Prop({ type: String })
  readonly label?: string
  @Prop({ type: String, default: 'text' })
  readonly type!: InputType
  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean
  @Prop({ type: Boolean, default: false })
  readonly textarea!: boolean
  @Prop({ type: Number })
  readonly maxlength?: number
  @Prop({ type: Number })
  readonly rows?: number
  @Prop({ type: String })
  readonly prepend?: string

  get isLocalized() {
    if (this.fieldValidator) {
      return (
        this.fieldValidator.value !== null &&
        typeof this.fieldValidator.value === 'object'
      )
    } else {
      return typeof this._value === 'object'
    }
  }

  get localValue() {
    if (this.fieldValidator) {
      if (this.isLocalized) {
        return Object.assign(emptyLocalizedField(), this.fieldValidator.value)
      }
      return this.fieldValidator.value
    } else {
      return this._value
    }
  }
  set localValue(value: any) {
    if (this.fieldValidator) {
      this.fieldValidator.set(value)
    }
    this._value = value
  }

  get displayedError() {
    if (this.fieldValidator) {
      return this.fieldValidator.errorText
    } else {
      this.error
    }
  }

  get displayedValue() {
    if (this.isLocalized) {
      return (
        (this.localValue as LocalizedField)[i18nStore.selectedLanguage] ?? ''
      )
    } else {
      return this.localValue
    }
  }

  onInput(value: any) {
    if (this.isLocalized) {
      ;(this.localValue as LocalizedField)[i18nStore.selectedLanguage] = value
      this.localValue = this.localValue
    } else if (this.type !== 'number') {
      this.localValue = value
    } else {
      const number = Number.parseFloat(value)
      this.localValue = isNaN(number) ? null : number
    }
  }

  protected render() {
    return (
      <BFormGroup
        label={this.label}
        description={this.description}
        class={[{ localized: this.isLocalized }, { textarea: this.textarea }]}
      >
        {this.$scopedSlots.top && this.$scopedSlots.top()}
        <div class="position-relative">
          {this.textarea ? (
            <BTextarea
              ref="inputEl"
              value={this.displayedValue as string}
              onInput={this.onInput}
              placeholder={this.placeholder}
              disabled={this.disabled}
              maxlength={this.maxlength}
              trim
              rows={this.rows}
              state={this.displayedError ? false : null}
              onFocus={() => this.$emit('focus')}
              onClick={() => this.$emit('click')}
            />
          ) : (
            <b-input-group prepend={this.prepend}>
              <BInput
                ref="inputEl"
                value={this.displayedValue}
                onInput={this.onInput}
                placeholder={this.placeholder}
                disabled={this.disabled}
                maxlength={this.maxlength}
                type={this.type}
                trim
                state={this.displayedError ? false : null}
                onFocus={() => this.$emit('focus')}
                onClick={() => this.$emit('click')}
              />
              {this.$scopedSlots.append && (
                <b-input-group-append>
                  {this.$scopedSlots.append()}
                </b-input-group-append>
              )}
            </b-input-group>
          )}
          {this.isLocalized && (
            <LanguageSelector
              onSelect={() => this.inputEl.focus()}
              class="flag"
            />
          )}
        </div>
        {this.displayedError && (
          <small class="text-danger" domPropsInnerHTML={this.displayedError} />
        )}
      </BFormGroup>
    )
  }
}
