import { Component } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import { BOverlay, BProgress } from '@/components/bootstrap'

export type Task = {
  action: () => Promise<any>
  title?: string
}

@Component
export default class LoaderOverlay extends tsx.Component<{}> {
  currentTask: Task | null = null
  taskIndex = 1
  tasksCount = 0
  title = ''

  async show(tasks: Task[], title = 'Идёт сохранение, подождите') {
    this.title = title
    this.tasksCount = tasks.length
    this.taskIndex = 1

    for (const task of tasks) {
      this.currentTask = task
      let result = true
      try {
        result = await task.action()
      } catch (error) {
        result = false
        console.error(error)
      }

      if (result === false) {
        this.currentTask = null
        return false
      }
      this.taskIndex++
    }

    this.currentTask = null
    return true
  }

  protected render() {
    return (
      <BOverlay
        rounded="sm"
        show={!!this.currentTask}
        scopedSlots={{
          overlay: () => (
            <div class="text-center">
              <h2>{this.title}</h2>
              <h3>{`${this.taskIndex}/${this.tasksCount} ${
                this.currentTask!.title || ''
              }`}</h3>
              <BProgress
                value={this.taskIndex}
                max={this.tasksCount}
                animated
              />
            </div>
          ),
        }}
      >
        {this.$slots.default}
      </BOverlay>
    )
  }
}
