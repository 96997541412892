import CollapseTransition from '@/components/transitions/Collapse'
import { i18nStore } from '@/store'
import { Language, languages } from '@/store/i18nStore'
import { Component } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import Flag from '../../Flag'
import './style.scoped.scss'

@Component
export default class LanguageSelector extends tsx.Component<{
  onSelect: (lang: Language) => void
}> {
  isOpen = false

  selectLanguage(lang: Language) {
    i18nStore.selectedLanguage = lang
    this.isOpen = false
    this.$emit('select', lang)
  }

  protected render() {
    return (
      <div class="selector" v-click-outside={() => (this.isOpen = false)}>
        <div onClick={() => (this.isOpen = !this.isOpen)}>
          <Flag language={i18nStore.selectedLanguage} />
        </div>
        <CollapseTransition hidden duration={0.1}>
          {this.isOpen && (
            <div class="dropdown border rounded shadow">
              {languages.map(lang => (
                <div
                  key={lang}
                  onClick={() => this.selectLanguage(lang)}
                  class="lang"
                >
                  <Flag language={lang} />
                </div>
              ))}
            </div>
          )}
        </CollapseTransition>
      </div>
    )
  }
}
