import { Language } from '@/store/i18nStore'
import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

@Component
export default class Flag extends tsx.Component<{
  language: Language
  size?: number
}> {
  @Prop({ type: String, required: true })
  readonly language!: Language
  @Prop({ type: Number, default: 20 })
  readonly size!: number

  protected render() {
    return (
      <img
        src={require(`@/assets/images/flags/${this.language}.svg`)}
        alt={this.language}
        style={{ width: `${this.size}px` }}
      />
    )
  }
}
