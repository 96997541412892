import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

@Component
export default class CollapseTransition extends tsx.Component<{
  duration?: number
  hidden?: boolean
}> {
  @Prop({ type: Number, default: 0.4 })
  readonly duration!: number
  @Prop({ type: Boolean, default: false })
  readonly hidden!: boolean

  lastTransitionStartedAt = 0

  onEnter(el: Element, done: () => void) {
    this.lastTransitionStartedAt = Date.now()

    const rect = el.getBoundingClientRect()
    const htmlEl = el as HTMLElement

    const height = rect.height
    const style = window.getComputedStyle(htmlEl)

    const marginTop = style.marginTop
    const marginBottom = style.marginBottom
    const paddingTop = style.paddingTop
    const paddingBottom = style.paddingBottom

    htmlEl.style.maxHeight = '0'
    htmlEl.style.marginTop = '0'
    htmlEl.style.marginBottom = '0'
    htmlEl.style.paddingTop = '0'
    htmlEl.style.paddingBottom = '0'
    htmlEl.style.opacity = '0'
    if (this.hidden) {
      htmlEl.style.overflow = 'hidden'
    }

    setTimeout(() => {
      htmlEl.style.transition = `${this.duration}s all`
      htmlEl.style.opacity = '1'
      htmlEl.style.maxHeight = `${height}px`
      htmlEl.style.marginTop = marginTop
      htmlEl.style.marginBottom = marginBottom
      htmlEl.style.paddingTop = paddingTop
      htmlEl.style.paddingBottom = paddingBottom
    }, 1)

    setTimeout(() => {
      done()

      if (
        new Date().getTime() - this.lastTransitionStartedAt >=
        this.duration * 1000
      ) {
        htmlEl.style.removeProperty('transition')
        htmlEl.style.removeProperty('overflow')
        htmlEl.style.removeProperty('max-height')
        htmlEl.style.removeProperty('margin-top')
        htmlEl.style.removeProperty('margin-bottom')
        htmlEl.style.removeProperty('padding-top')
        htmlEl.style.removeProperty('padding-bottom')
        htmlEl.style.removeProperty('opacity')
      }
    }, this.duration * 1000)
  }

  onLeave(el: Element, done: () => void) {
    this.lastTransitionStartedAt = Date.now()

    const rect = el.getBoundingClientRect()
    const htmlEl = el as HTMLElement
    const height = rect.height

    htmlEl.style.maxHeight = `${height}px`
    htmlEl.style.marginTop = '0'
    htmlEl.style.marginBottom = '0'
    htmlEl.style.paddingTop = '0'
    htmlEl.style.paddingBottom = '0'
    htmlEl.style.opacity = '0'
    htmlEl.style.transition = `${this.duration}s all`
    if (this.hidden) {
      htmlEl.style.overflow = 'hidden'
    }

    setTimeout(() => {
      htmlEl.style.maxHeight = '0'
    }, 1)

    setTimeout(done, this.duration * 1000)
  }

  protected render() {
    return (
      <transition css={false} onEnter={this.onEnter} onLeave={this.onLeave}>
        {this.$slots.default}
      </transition>
    )
  }
}
